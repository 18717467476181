import axiosIns from '@/libs/axios'
import utilsService from './utilsService'

const controller = 'challenge'

export default {
  async getAll(filter) {
    return axiosIns
      .get(`${controller}?${utilsService.getQueryParams(filter)}`)
      .then(res => res.data)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async createQuiz(req) {
    return axiosIns.post(controller + '/quiz', req).then(res => res.data)
  },
  async updateQuiz(req) {
    return axiosIns.put(controller + '/quiz', req).then(res => res.data)
  },
  async getForReview(filter) {
    return axiosIns
      .get(`${controller}/avaliar?${utilsService.getQueryParams(filter)}`)
      .then(res => res.data)
  },

  // Avaliação de Arboturma
  async getArboTurma(id) {
    return axiosIns.get(`arboturma/grupo/${id}`).then(res => res.data)
  },
  async getJanelaTanque(id) {
    return axiosIns.get(`janelaTanqueAnswer/${id}`).then(res => res.data)
  },
  async getPatrulhaFoco(id) {
    return axiosIns.get(`patrulhaAnswer/${id}`).then(res => res.data)
  },
  async avaliar(url, obj) {
    return axiosIns.put(`avaliar/${url}`, obj).then(res => res.data)
  },



}
